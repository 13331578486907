import React, { useMemo } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useGlobalContext } from '../../../GlobalContext';
import Flash from '../../../partials/Flash';
import InputNumber from '../../../partials/form_fields/InputNumber';
import { updateOrder } from '../../../../actions/orders';
import { fnFormat, fnFormatFixed } from '../../../../helpers/helpers';
import IconGift from '../../../../assets/images/icons/icon-gift-solid.svg';
import IconPunch from '../../../../assets/images/icons/icon-punch.png';
import { API_URL } from '../../../../actions';

import ImageItem from './ImageItem';

export default function LineProduct({
  product, count, orderByCompany, orderConfirmedByCompany, fnUpdateOrder, fnCreateOrder,
}) {
  const history = useHistory();
  const dispatch = useGlobalContext()[1];
  const _updateOrder = (order, id) => updateOrder(dispatch, order, id);

  const handleCountProduct = (count) => {
    if (orderConfirmedByCompany[product.company._id]) {
      const r = window.confirm('Vous allez modifier une commande en cours avec ce fournisseur. Vous allez devoir la valider à nouveau en scannant le badge du fournisseur. Etes-vous sûr ?');
      if (r == true) {
        fnUpdateOrder(orderConfirmedByCompany[product.company._id], product, count, () => {
          history.push(`/order/${orderConfirmedByCompany[product.company._id]}`);
        });
      }
    } else {
      const orderId = orderByCompany[product.company._id];
      if (orderId) {
        fnUpdateOrder(orderId, product, count);
      } else {
        fnCreateOrder(product, count);
      }
    }
  };

  function confirm(e, orderId) {
    e.preventDefault();
    e.stopPropagation();

    const r = window.confirm('Vous allez modifier une commande en cours avec ce fournisseur. Vous allez devoir la valider à nouveau en scannant le badge du fournisseur. Etes-vous sûr ?');
    if (r == true) {
      const obj = {
        status: 'pending',
      };
      _updateOrder(obj, orderId).then(() => {
        history.push(`/order/${orderId}`);
      });
    }
  }

  return useMemo(() => (
      <NavLink to={`/products/${product._id}`} id={`product-${product._id}`}>
        {product.flash > 0 && <Flash/> }
        { product.discount
          && <>
            {!isNaN(parseInt(product.discount))
              ? <div className="discount">
                <p>-{fnFormatFixed(product.discount)}<span className="unit">%</span></p>
              </div>
              : <div className="discount">
                {product.discount === 'Coup de poing'
                  && <img className="punch" src={IconPunch} alt="opération coup de point" />
                }
                {product.discount === 'Gratuité'
                  && <img src={IconGift} alt="cadeau" />
                }
              </div>
            }
          </>
        }

        <div className="container-thumbnail-tablet only-on-tablet">
          {product?.image?.path && (
            <ImageItem
              className="thumbnail-tablet only-on-tablet"
              src={`${API_URL}/files/${product.image.path.replace('upload/', '')}`}
              alt={product.segment}
            />
          )}

        </div>
        <div className="product-provider hide-on-tablet">
          <p>{product.company.name ? product.company.name : product.company}</p>
        </div>
        <div className="product-segment hide-on-tablet">
          <p>{product.segment}</p>
        </div>
        <div className="product-gencod hide-on-tablet">
          <p>{product.gencod}</p>
        </div>
        <div className="product-ref">
          <div className="mobile-segment-ref only-on-tablet">
            <p>{product.company.name ? product.company.name : product.company}</p>
            <p>{product.segment}</p>
            <p>Réf : {product.ref}</p>
          </div>
          <div className="hide-on-tablet">
            {product?.image?.path && (
              <ImageItem
                key={`thumbnail-${product.gencod}`}
                className="thumbnail"
                src={`${API_URL}/files/${product.image.path.replace('upload/', '')}`}
                alt={product.segment}
              />
            )}
            <p className="hide-on-tablet">
              {product.ref}
            </p>
          </div>
        </div>
        <div className="product-packing hide-on-tablet">
          <p>{product.packing}</p>
        </div>
        <div className="product-price">
          <p>{product.price ? `${fnFormat(product.price)}€` : 'Non renseigné'}</p>
        </div>
        <div className="product-qty">
          <span className="only-on-tablet">
            <div className="product-packing">
              <p>Colisage : {product.packing}</p>
            </div>
          </span>
          { orderConfirmedByCompany[product.company._id] && count > 0
            ? <button className="goto-order secondary" onClick={(e) => confirm(e, orderConfirmedByCompany[product.company._id])} >Modifier</button>
            : <InputNumber value={count}
            handleChange={
              (count) => handleCountProduct(count)}/>
          }

        </div>
      </NavLink>
  ), [product, count, orderByCompany]);
}

import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { useGlobalContext } from '../../../GlobalContext';
import { getProductByID, setProductsFilters, resetSelectedProduct } from '../../../../actions/products';
import { createOrder, updateOrder } from '../../../../actions/orders';
import InputNumber from '../../../partials/form_fields/InputNumber';
import Flash from '../../../partials/Flash';
import ImageItem from './ImageItem';
import TooltipBox from './TooltipBox';
import IconGift from '../../../../assets/images/icons/icon-gift-solid.svg';
import IconPunch from '../../../../assets/images/icons/icon-punch.png';

import { fnFormat, fnFormatFixed } from '../../../../helpers/helpers';
import { API_URL } from '../../../../actions';

export default function Product() {
  const [context, dispatch] = useGlobalContext();
  const { authReducer } = context;
  const { productsReducer } = context;
  const params = useParams();
  const history = useHistory();
  const [product, setProduct] = useState();
  const [orderConfirmed, setOrderConfirmed] = useState();

  // ACTIONS
  const _getProductByID = (id) => getProductByID(dispatch, id);
  const _setProductsFilters = (filters) => setProductsFilters(dispatch, filters);
  const _createOrder = (order) => createOrder(dispatch, order);
  const _updateOrder = (order, id) => updateOrder(dispatch, order, id);
  const _resetSelectedProduct = () => resetSelectedProduct(dispatch);

  useEffect(() => {
    _getProductByID(params.id);
  }, []);

  // IF WE LEAVE PRODUCTS LIST
  // FOR AN OTHER PAGE THAN PRODUCT PAGE
  // CLEAR SAVED FILTERS
  useEffect(() => {
    const unlisten = history.listen((location) => {
      if (!/\/products/.test(location.pathname)) {
        _resetSelectedProduct();
        _setProductsFilters({
          search: null,
          sort: {},
          type: null,
          provider: null,
        });
      }
    });
    return () => {
      unlisten();
    };
  }, []);

  useEffect(() => {
    if (!productsReducer.orders || !productsReducer.product) return;
    let p = productsReducer.product;
    productsReducer.orders.forEach((order) => {
      const orderId = order._id;
      const countBy = _.countBy(order.products, '_id');

      for (const id in countBy) {
        if (id === productsReducer.product._id) {
          p = { ...p, orderId, count: countBy[id] };
        }
      }
      if (order.status === 'confirmed') {
        if (order.products[0].company._id === productsReducer.product.company._id) {
          setOrderConfirmed(order._id);
        }
      }
    });

    setProduct(p);
  }, [productsReducer.orders, productsReducer.product]);

  function handleCountProduct(count) {
    if (orderConfirmed) {
      const r = window.confirm('Vous allez modifier une commande en cours avec ce fournisseur. Vous allez devoir la valider à nouveau en scannant le badge du fournisseur. Etes-vous sûr ?');
      if (r == true) {
        fnUpdateOrder(orderConfirmed, product, count, () => {
          history.push(`/order/${orderConfirmed}`);
        });
      }
    } else if (product.orderId) {
      fnUpdateOrder(product.orderId, product, count);
    } else {
      fnCreateOrder(product, count);
    }
  }

  function fnUpdateOrder(orderID, product, count, callback) {
    const order = productsReducer.orders.find((order) => order._id === orderID);
    const updateOrder = _.cloneDeep(order);

    updateOrder.products = updateOrder.products.filter((p) => p._id !== product._id);
    updateOrder.products = [...updateOrder.products, ...new Array(count).fill(product, 0, count)];

    const obj = {
      products: updateOrder.products,
      company: authReducer.user.company,
      user: authReducer.user._id,
      status: 'pending',
    };

    _updateOrder(obj, orderID).then(() => {
      if (callback) callback();
    });
  }

  function fnCreateOrder(product, count) {
    const arrProducts = new Array(count).fill(product, 0, count);

    const obj = {
      products: arrProducts,
      company: authReducer.user.company,
      user: authReducer.user._id,
    };

    _createOrder(obj);
  }

  function confirm(orderId) {
    const r = window.confirm('Vous allez modifier une commande en cours avec ce fournisseur. Vous allez devoir la valider à nouveau en scannant le badge du fournisseur. Etes-vous sûr ?');
    if (r == true) {
      const obj = {
        status: 'pending',
      };
      _updateOrder(obj, orderId).then(() => {
        history.push(`/order/${orderId}`);
      });
    }
  }

  return (
    <div className="product">
      {product
        && <div className="content large">
          <div className="title-page">
            <h2>{product.ref}</h2>
            <p>{product.segment}</p>
          </div>
          <NavLink to="/products">
            <button>Retour</button>
          </NavLink>
          <div className={'container-product'}>
            <div className="detail-product">
              {product.discount
                && <>
                  {!isNaN(parseInt(product.discount))
                    ? <Flash />
                    : <div className="gift">
                      {product.discount === 'Coup de poing'
                        && <img className="punch" src={IconPunch} alt="opération coup de point" />
                      }
                      {product.discount === 'Gratuité'
                        && <img src={IconGift} alt="cadeau" />
                      }
                    </div>
                  }
                </>
              }
              <div className="container-image">
                {product?.image?.path && (
                  <ImageItem
                    key={`image-product-${product.gencod}`}
                    src={`${API_URL}/files/${product.image.path.replace('upload/', '')}`}
                    alt={product.segment}
                  />
                )}
              </div>
              <section className="first-section">
                <div className="product-qty-mobile">
                  {orderConfirmed && product.count > 0
                    ? <button className="goto-order secondary" onClick={() => confirm(orderConfirmed)} >Modifier</button>
                    : <>
                      <InputNumber
                        value={product.count ? product.count : 0}
                        handleChange={(count) => handleCountProduct(count, product.price)}
                      />
                    </>
                  }
                </div>

                {product.discount
                  && <>
                    {!isNaN(parseInt(product.discount))
                      ? <div>
                        <label>Avantage salon :</label>
                        <p className="promo">{product.discount ? `${fnFormatFixed(product.discount)}%` : 0}</p>
                      </div>
                      : <>
                        {product.discount === 'Gratuité' && <div className="container-gift">
                          <label>Offre spéciale&nbsp;:&nbsp;</label>
                          <p>{product.gift}</p>
                        </div>}
                      </>
                    }
                  </>
                }

                <div>
                  <label>Prix net salon HT/unité :</label>
                  <p className="product-price">{product.price ? `${fnFormat(product.price)}€` : 'Non renseigné'}</p>
                </div>
                <div className="product-qty">
                  {orderConfirmed && product.count > 0
                    ? <button className="goto-order secondary" onClick={() => confirm(orderConfirmed)} >Modifier</button>
                    : <>
                      <InputNumber
                        value={product.count ? product.count : 0}
                        handleChange={(count) => handleCountProduct(count, product.price)}
                      />
                    </>
                  }
                </div>
              </section>
              <section>
                <div>
                  <label>Fournisseur :</label>
                  <p>{product.company.name}</p>
                </div>
                <div>
                  <label>Désignation produit :</label>
                  <p>{product.description}</p>
                </div>
                <div>
                  <label>Marque :</label>
                  <p>{product.brand}</p>
                </div>
              </section>
              <section>
                <div>
                  <label>Référence produit :</label>
                  <p>{product.ref}</p>
                </div>
                <div>
                  <label>Gencod :</label>
                  <p>{product.gencod}</p>
                </div>
                <div className="cell-packing">
                  {product.box
                    ? <>
                      <label>Détail box&nbsp;:&nbsp;</label>
                      <TooltipBox text={product.box} />
                    </>
                    : <>
                      <label>Colisage&nbsp;:&nbsp;</label>
                      <p>{product.packing}</p>
                    </>
                  }
                </div>
              </section>
              <section>
                <div>
                  <label>PA permanent :</label>
                  <p>{product.pricePA ? `${fnFormat(product.pricePA)}€` : '---'}</p>
                </div>
                <div>
                  <label>DEEE HT :</label>
                  <p>{product.deee ? `${fnFormat(product.deee)}€` : '---'}</p>
                </div>
                <div>
                  <label>Autres taxes :</label>
                  <p>{product.tax ? `${product.tax}€` : '---'}</p>
                </div>
              </section>
              <section>
                <div>
                  <label>Typo :</label>
                  <p>{product.product}</p>
                </div>
                <div>
                  <label>Code article :</label>
                  <p>{product.code}</p>
                </div>
                <div>
                  <label>Numéro de dossier :</label>
                  <p>{product.fileNumber}</p>
                </div>
              </section>
            </div>
            {product?.image?.path && (
              <ImageItem
                key={`image-desktop-${product.gencod}`}
                src={`${API_URL}/files/${product.image.path.replace('upload/', '')}`}
                alt={product.segment}
              />
            )}
          </div>
        </div>
      }

    </div>
  );
}
